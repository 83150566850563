import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp07 temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<div className="content-container">
						<div className="office_inner">
							<div className="temp02_history">
								<h2 className={"large bold"}>
									放射温度計は、なぜ、触れずに測れるのだろう。
								</h2>
								<h2 className="temp02_font_title medium">
									高温域測定には短波長、低温域測定には長波長が適しています。
								</h2>
							</div>
							<div className="flex-container">
								<div className="temp03_infrared">
									<img
										className="temp07_image"
										src="/asset/chart_e.gif"
										width="300"
										hight="300"
										alt="グラフ"
									/>
								</div>
								<div className="temp03_flex2">
									<ul className="temp03_ul_flex">
										<li className="temp03_flex2">
											<p className="medium">
												では、具体的にどの波長域を選べはよいのでしょう。
											</p>

											<p className="medium">図Eは、大気による吸収がない</p>

											<p className="medium">
												波長1.7μm*と10μmの断面図です。２つの断面図を比較してみると、
											</p>

											<p className="medium">
												1.7μmでは赤外線エネルギーの放射が低温域ではほとんどなく、高温域で強いことがわかります。
											</p>

											<p className="medium">
												また、10μmでは1.7μmに比べて低温域で赤外線エネルギーの放射が強く、高温域では弱いことがわかります。
											</p>

											<p className="medium">
												したがって、高温の温度測定には1.7μmの短波長の方が適しており、低温の温度測定には10μmの長波長の方が適していることがわかります。
											</p>
										</li>
									</ul>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp03_infrared">
									<img
										className="temp07_image"
										src="/asset/chart_f.gif"
										width="300"
										hight="300"
										alt="グラフ"
									/>
								</div>
								<div className="temp03_flex2">
									<ul className="temp03_ul_flex">
										<li className="temp03_flex2">
											<p className="medium">
												また、これは図F*（図Eを上から見たときのピークの図）によっても、確認することができます。
											</p>

											<p className="medium">
												ピーク（赤外線エネルギーの項上）の描く軌跡（特性曲線）は、高温ほど短波長側に、低温ほど長波長側に寄っています。
											</p>

											<p className="medium">
												このことからも、短波長側を高温測定に用い、長波長測を低温測定に用いるのが一般的な波長域の選び方といえます。
											</p>

											<p className="medium">
												放射温度計では1μm付近の短波長を600℃～3,000℃の高温域測定に用い、
											</p>

											<p className="medium">
												10μm付近（8～14μm）の長波長を-50℃～1,000℃の低温域測定に用いています。
											</p>

											<a
												href="/support/documents/about-temperature/temp10#wavelength_unit"
												className="temp01_absolutetemperature"
											>
												*波長の単位（温度計一口メモ）
											</a>
											<br />

											<a
												href="/support/documents/about-temperature/temp10#absolute_temperature"
												className="temp01_absolutetemperature"
											>
												*ウィーンの変位測（温度計一口メモ）
											</a>
										</li>
									</ul>
								</div>
							</div>

							<div className="temp02_history ">
								<div className="temp07_border">
									<h2 className="temp02_font_title medium">
										4.赤外線の放射率について
									</h2>
								</div>

								<div>
									<p className="medium">
										可視光線の領域では、光をよく反射する物体は白く見え、光を反射せず吸収する物体は黒く見えます。
									</p>

									<p className="medium">
										同じように赤外領域でも赤外線をよく反射するものを白い物体といい、吸収するものを黒い物体と表現しています。
									</p>

									<p className="medium">
										そして、あらゆる光を完全に吸収する物体を完全黒体といいます。
									</p>

									<p className="medium">
										また、赤外線をよく吸収する物体ほど、赤外線をよく放射します。*
									</p>

									<p className="medium">
										完全黒体の性質は光を反射せず、あらゆる光を吸収し、プランクの放射法則*に応じた赤外線を放射します。
									</p>

									<p className="medium">
										しかし、現実には同じ温度の物体でも材質や表面状態などによって、それぞれ赤外線の放射量はちがっています。
									</p>

									<p className="medium">
										同じ温度の完全黒体と実際の物体の波長特性を比べたのが図Gです。
									</p>

									<p className="medium">
										実際の物体は完全黒体に比べ赤外線を放射する割合は少なくなっています。
									</p>

									<p className="medium">
										この完全黒体の特性曲線と実際の物体の特性曲線の面積比を放射率と呼んでいます。
									</p>

									<p className="medium">
										完全黒体の放射を１として算出していますから、通常の物体の放射率はすべて１以下ということになります。
									</p>

									<p className="medium">
										放射温度計で実際の物体を測定する場合は、本体に放射率を設定する機能が備えてありますから、さまざまな物体の正確な温度測定が可能です。
									</p>

									<p className="medium">
										実際にはそれぞれに採用されている測定波長（例えば8～14μm）の面積比が放射率になります。
									</p>

									<p className="medium">
										また、放射率は、放射温度計の測定波長によってそれぞれ異なりますので、放射温度計であればすべて同じ放射率になるとは限りません。
									</p>

									<a
										href="/support/documents/about-temperature/temp10#thermal_equilibrium"
										className="temp01_absolutetemperature"
									>
										*キルヒホッフの法則（温度計一口メモ）
									</a>
									<br />
									<a
										href="/support/documents/about-temperature/temp10#wavelength_unit"
										className="temp01_absolutetemperature"
									>
										*プランクの放射法則（温度計一口メモ）
									</a>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp03_infrared text_align_center">
									<img
										className="temp07_image"
										src="/asset/chart_g.gif"
										width="250"
										hight="600"
										alt="グラフ"
									/>
								</div>
							</div>

							<div className="flex-container">
								<p className="medium">
									主な物体の放射率の目安(金属は酸化されたものです)
								</p>
							</div>

							<div>
								<div className="flex column temp07-fifth">
									<table>
										<tr>
											<th className="temp07_th">
												<p className="medium">物体</p>
											</th>
											<th className="temp07_th">
												<p className="medium">放射率 (ε)</p>
											</th>
										</tr>
										<tr>
											<td>
												<p className="medium">鉄</p>
											</td>
											<td>
												<p className="medium">0.85</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">ニッケル</p>
											</td>
											<td>
												<p className="medium">0.85</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">アルミ</p>
											</td>
											<td>
												<p className="medium">0.30</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">銅</p>
											</td>
											<td>
												<p className="medium">0.80</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">真鍮</p>
											</td>
											<td>
												<p className="medium">0.60</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">ニクロム</p>
											</td>
											<td>
												<p className="medium"> 0.60</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">ガラス</p>
											</td>
											<td>
												<p className="medium">0.85</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">セラミック</p>
											</td>
											<td>
												<p className="medium"> 0.80</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">タイル</p>
											</td>
											<td>
												<p className="medium">0.80</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">アスベスト</p>
											</td>
											<td>
												<p className="medium">0.90</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">アスファルト</p>
											</td>
											<td>
												<p className="medium">0.85</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">コンクリート</p>
											</td>
											<td>
												<p className="medium">0.65</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">土</p>
											</td>
											<td>
												<p className="medium">0.95 </p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">木材</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">紙</p>
											</td>
											<td>
												<p className="medium">0.92</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">布</p>
											</td>
											<td>
												<p className="medium">0.75</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">プラスチック</p>
											</td>
											<td>
												<p className="medium"> 0.95</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">ゴム</p>
											</td>
											<td>
												<p className="medium">0.95</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">カーボン</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">皮膚</p>
											</td>
											<td>
												<p className="medium">0.97</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">水</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">海水</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">雲</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">肉・魚</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">野菜</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">パン・菓子</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">穀類</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">馬・豚</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">油</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
										<tr>
											<td>
												<p className="medium">塗料</p>
											</td>
											<td>
												<p className="medium">0.98</p>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
					<PagingController
						current={7}
						next={"/support/documents/about-temperature/temp08"}
						prev={"/support/documents/about-temperature/temp06"}
					/>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
